import { FC, PropsWithChildren } from 'react';
import { Container, LinearProgress } from '@mui/material';

import clsx from 'clsx';
import Head from 'next/head';

const LoadingSkeleton: FC<PropsWithChildren<{}>> = ({ children = '請稍候' }) => (
  <>
    <Head>
      <title>請稍候</title>
    </Head>
    <Container maxWidth="xs" disableGutters>
      <div className="relative h-screen h-screen-ios">
        <div className={clsx(
          'w-full p-10',
          'absolute transform -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2',
        )}
        >
          <div className="text-center text-sm text-gray-500 py-3">
            {children}
          </div>
          <LinearProgress color="success" />
        </div>
      </div>
    </Container>
  </>
);

export default LoadingSkeleton;
