// eslint-disable-next-line import/no-extraneous-dependencies
import 'tailwindcss/tailwind.css';

import type { AppProps } from 'next/app';

import GlobalSuspense from '@/c/wrappers/GlobalSuspense';
import ReactQueryProvider from '@/c/wrappers/ReactQueryProvider';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <GlobalSuspense>
      <ReactQueryProvider>
        <Component {...pageProps} />
      </ReactQueryProvider>
    </GlobalSuspense>
  );
}
export default MyApp;
