import { FC, PropsWithChildren, Suspense } from 'react';

import LoadingSkeleton from '@/c/common/LoadingSkeleton';

const GlobalSuspense: FC<PropsWithChildren<{}>> = ({ children }) => (
  typeof window !== 'undefined'
    ? (
      <Suspense fallback={<LoadingSkeleton />}>
        {children}
      </Suspense>
    )
    : (<>{children}</>)
);

export default GlobalSuspense;
