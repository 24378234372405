import { ReactQueryDevtools } from 'react-query/devtools';
import { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: typeof window !== 'undefined',
    },
  },
});

const ReactQueryProvider: FC<PropsWithChildren<{}>> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <>{children}</>
  </QueryClientProvider>
);

export default ReactQueryProvider;
